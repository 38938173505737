import React from 'react'
import AboutServices from '../home/AboutUsPage/AboutServices'
import AboutService from '../home/ServicePage/AboutService'
import Service from '../home/homes/Service'
import Tech from '../home/homes/Tech'
import ServiceFeatures from '../home/ServicePage/ServiceFeatures'

const ServicePage = () => {
  return (
    <>
       <Service/>
       <AboutService/>
       
    </>
  )
}

export default ServicePage
import React from 'react'


const AboutDir = () => {
  return (
    <>
        
    </>
  )
}

export default AboutDir
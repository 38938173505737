import React from 'react'
import Form from '../home/ContactPage/Form'
import Map from '../home/ContactPage/Map'
import Contact from '../home/homes/Contact'



const ContactPage = () => {
  return (
    <>
      <Contact/>
      <Form/>
      <Map/>
      
    </>
  )
}

export default ContactPage